// Normaliser une chaîne de caractères pour la recherche
// "démo" => "demo"
// "Demonstration Eficia" => "demonstration eficia"
export const normalizeString = (str) => {
  if (!str) {
    return '';
  }
  return (
    `${str}`
      // Enlever les accents
      .normalize('NFD')
      .replaceAll(/[\u0300-\u036f]/g, '')
      // Supprimer les caractères spéciaux les plus communs sans supprimer des caractères non-latin
      .replaceAll(/[\\\-_.,:;]/g, ' ')
      // Enlever les successions de multiple espaces
      .replaceAll(/\s{2,}/g, ' ')
      .trim()
      .toLowerCase()
  );
};
