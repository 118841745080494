import styled from 'styled-components';

export const Message = styled.div`
  &.sender-bot > span,
  &.sender-bot li {
    display: inline;
    color: var(--text-caption);
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }

  &.sender-user span {
    float: right;
    color: white;
    background-color: var(--text-action);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    border-radius: 10px;
    padding: 2px 10px 2px 10px;
    margin-left: 30px;
  }
`;

export const MarkdownContainer = styled.span`
  p {
    margin: 0;
  }

  ul,
  ol {
    padding-left: 12px;
    margin-bottom: 0;
  }

  a {
    color: var(--text-action);
    text-decoration: underline;
  }
`;

export const ImageIcon = styled.div`
  img {
    width: 15px;
    height: 15px;
    filter: var(--text-action-filter);
  }
`;
