import styled from 'styled-components';

export const BadgeContainer = styled.div`
  display: inline-block;
  border-radius: 4px;
  white-space: nowrap;

  svg {
    color: var(--text-body);

    &:hover {
      color: var(--text-action);
    }
  }
`;
