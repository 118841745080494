export function unregister() {
  window.addEventListener('error', (e) => {
    if (/Loading chunk [\d]+ failed/.test(e.message)) {
      window.location.reload();
    }
  });
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}
