import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { siteProps } from 'eficia/types/sites';
import PropTypes from 'prop-types';

import { UserContext } from 'eficia/contexts/UserProvider';

import useAnalyzeUserMessage from '../services/chatbot/useAnalyzeUserMessage';
import { ChatbotHistory } from './ChatbotHistory';
import { ChatbotInput } from './ChatbotInput';

// Veuillez à être le plus retro-compatible si vous ne changez pas la version
// Chaque changement de version videra l'historique des utilisateurs
const LOCAL_STORAGE_VERSION = 'v1';
const LOCAL_STORAGE_LAST_MESSAGES_NBR = 50;
const SENDER_BOT = 'bot';
const SENDER_USER = 'user';

export function Chatbot({ aiToken, site }) {
  const { t } = useTranslation();

  const [messages, setMessages] = useState([]);

  const [scrollRef, setScrollRef] = useState(null);

  const { userData } = useContext(UserContext);

  const localStorageKey = `chatbot_${site.id}_${userData.id}_${LOCAL_STORAGE_VERSION}`;

  useEffect(() => {
    // Sauvegarde uniquement si l'utilisateur a en moins dit un message
    if (messages.some((message) => message.sender === SENDER_USER)) {
      // Sauvegarde uniquement les X derniers messages
      // afin d'alléger l'affichage de l'historique une fois chargé
      const lastMessages = messages.filter(
        (val, index, arr) => index > arr.length - LOCAL_STORAGE_LAST_MESSAGES_NBR - 1
      );

      localStorage.setItem(localStorageKey, JSON.stringify(lastMessages));
    }

    // Scroll tout en bas pour voir le dernier message
    if (scrollRef) {
      scrollRef.scrollTop = scrollRef.scrollHeight;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  useEffect(() => {
    const existingMessages = localStorage.getItem(localStorageKey);
    if (existingMessages) {
      // Charger les messages précédents de cette discussion
      setMessages(JSON.parse(existingMessages));
    } else {
      // Message de bienvenue de cette discussion
      setMessages([
        {
          data: [
            { object: 'badge', label: site.name },
            t('chatbot.welcome_message.first_line', {
              siteName: site.name,
              interpolation: { escapeValue: false }
            }),
            {
              object: 'button-group',
              buttons: [
                {
                  icon: 'temperature-list',
                  label: t('chatbot.welcome_message.button.check_temperature')
                },
                {
                  icon: 'temperature-change',
                  label: t('chatbot.welcome_message.button.change_temperature')
                }
              ]
            }
          ],
          sender: SENDER_BOT
        }
      ]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const analyzeUserMessage = useAnalyzeUserMessage(aiToken);

  const handleSubmit = (message) => {
    // Optimistic-behavior
    const newMessages = [...messages, { data: [message], sender: SENDER_USER }];
    setMessages(newMessages);

    // Scroller tout en bas après un temps d'attente pour afficher le "..." du bot
    window.setTimeout(() => {
      if (scrollRef) {
        scrollRef.scrollTop = scrollRef.scrollHeight;
      }
    }, 2000);

    analyzeUserMessage(
      { query: message },
      {
        onSuccess: (response) => {
          setMessages([
            ...newMessages,
            {
              data: Array.isArray(response?.data?.data)
                ? response?.data?.data
                : [t('chatbot.error_occurred.message')],
              sender: SENDER_BOT
            }
          ]);
        },
        onError: () => {
          setMessages([
            ...newMessages,
            {
              data: [t('chatbot.error_occurred.message')],
              sender: SENDER_BOT
            }
          ]);
        }
      }
    );
  };

  return (
    <div
      data-testid="chatbot"
      className="d-flex flex-column"
      style={{ gap: 16, position: 'relative', height: '100%', width: '100%' }}
    >
      <div
        style={{
          display: 'flex',
          width: 'calc(100% + 15px)',
          height: 'calc(100% - 55px)',
          position: 'absolute',
          overflow: 'hidden',
          top: 0,
          bottom: 55
        }}
      >
        <PerfectScrollbar style={{ width: '100%', paddingRight: 15 }} containerRef={setScrollRef}>
          <ChatbotHistory messages={messages} onSubmit={handleSubmit} />
        </PerfectScrollbar>
      </div>
      <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
        <ChatbotInput onSubmit={handleSubmit} />
      </div>
    </div>
  );
}

Chatbot.propTypes = {
  aiToken: PropTypes.string.isRequired,
  site: siteProps.isRequired
};
