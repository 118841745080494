import PropTypes from 'prop-types';
import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';

import MultiSelect from 'eficia/components/molecules/MultiSelect/MultiSelect';

import { ErrorWrapper } from './FormMultiSelect.style';

// TODO: L'utlisation de ref n'est pas encore utilisable dans ce composant
// L'enregistrement des données du Multiselect dans le formularie est gérée manuellement pour le moment.
// https://react-hook-form.com/v6/api#register
export default function FormMultiSelect({
  data,
  formProps,
  name,
  validator = {},
  onError,
  placeholder = '',
  label = '',
  onSubmit,
  submitDisabled,
  badge = false,
  operator,
  onClick,
  mode = 'multiSelect',
  handleOnChange = null,
  isDataMutable = false,
  ...rest
}) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const [formattedData, setFormattedData] = useState(data);
  const [errorState, setErrorState] = useState(false);

  const {
    setValue: RHFSetValue,
    register,
    formState: { errors },
    getValues
  } = formProps;
  const [value, setValue] = useState([]);

  const currentError = errors[name];
  const { t } = useTranslation();

  const texts = {
    label,
    placeholder,
    tagPlaceholder: rest.tagPlaceholder,
    inlineSearchPlaceholder: t('multiselect.search.placeholder'),
    noMatches: t('multiselect.search.no_match')
  };

  function handleChange(current, nodes) {
    let formatResult = null;

    if (mode === 'simpleSelect') {
      // Le simpleSelect ne doit retourner qu'une seule valeur
      formatResult = nodes?.[0]?.value || null;
    } else {
      // On format le retour en récupérant uniquement la "value" dans un tableau
      formatResult = nodes.reduce((acc, curr) => [...acc, curr.value], []);
    }

    // On modifie la donnée manuellement pour notre useForm
    RHFSetValue(name, formatResult, { shouldDirty: true });
    setErrorState(false);

    if (handleOnChange) {
      // On retourne la donnée formatée au composant parent
      handleOnChange(formatResult);
    }
  }

  const sitesSelect = useMemo(() => {
    return (
      <MultiSelect
        onClick={onClick}
        isMobile={isMobile}
        badge={badge}
        onSubmit={onSubmit && onSubmit}
        submitDisabled={submitDisabled && submitDisabled}
        isDataMutable={isDataMutable}
        data={formattedData}
        onChange={handleChange}
        texts={texts}
        operator={operator}
        mode={mode}
        {...rest}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedData, operator]);

  const currentValue = getValues(name);
  useEffect(() => {
    setValue(currentValue || []);
  }, [currentValue]);

  useEffect(() => {
    const values = mode === 'simpleSelect' ? [value] : value;
    const defaultValueToUpdate = values.map((value) => String(value));

    // On formate la donnée afin de mettre à jour les valeurs par default du multiselect via les données renseignées par l'url au chargement de la page.
    const newData = data.map((parentData) => {
      if (!parentData.children) {
        return {
          icon: parentData.icon,
          label: parentData.label,
          value: parentData.value,
          checked: defaultValueToUpdate.includes(String(parentData.value)),
          isSubHeader: parentData.isSubHeader || false
        };
      }

      const formatChildren = parentData.children.map((childrenData) => ({
        ...childrenData,
        checked: defaultValueToUpdate.includes(String(childrenData.value)),
        isSubHeader: childrenData.isSubHeader || false
      }));

      return {
        expanded: true,
        icon: parentData.icon,
        label: parentData.label,
        value: parentData.value,
        children: formatChildren
      };
    });

    setFormattedData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, data]);

  useEffect(() => {
    const hasError = !!currentError;
    setErrorState(hasError);
    if (hasError) {
      onError && onError();
    }
  }, [currentError, name, onError]);

  // Enregistrement manuel
  useEffect(() => {
    register(`${name}`, validator);
  }, [register, name, validator]);

  return (
    <ErrorWrapper $error={errorState}>
      {sitesSelect}
      {errorState && (
        <span data-testid="multiselect-error" className="error" style={{ marginLeft: 14 }}>
          {currentError.message}
        </span>
      )}
    </ErrorWrapper>
  );
}

FormMultiSelect.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.object,
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          icon: PropTypes.object,
          label: PropTypes.string.isRequired,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
        })
      )
    })
  ),
  formProps: PropTypes.shape({
    getValues: PropTypes.func,
    watch: PropTypes.func,
    formState: PropTypes.object,
    errors: PropTypes.object,
    setValue: PropTypes.func,
    register: PropTypes.func,
    reset: PropTypes.func
  }).isRequired,
  name: PropTypes.string.isRequired,
  validator: PropTypes.object,
  placeholder: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onError: PropTypes.func,
  onSubmit: PropTypes.func,
  onClick: PropTypes.func,
  submitDisabled: PropTypes.bool,
  badge: PropTypes.bool,
  operator: PropTypes.string,
  mode: PropTypes.oneOf(['multiSelect', 'simpleSelect']),
  handleOnChange: PropTypes.any,
  isDataMutable: PropTypes.bool
};
