import { stringifyQuery } from 'eficia/utilities/query';
import { isIOS } from 'eficia/utilities/platform';

export function redirectToPreviousPage(navigate, pathname, page) {
  const parsedPage = parseInt(page) || 0;
  navigate(
    `${pathname}${stringifyQuery({
      page: parsedPage <= 1 ? 1 : parsedPage - 1
    })}`
  );
}

export function handleExternalRedirection(url) {
  if (isIOS()) {
    const linkElement = document.createElement('a');
    linkElement.id = 'link';
    window.document.body.appendChild(linkElement);
    const link = document.getElementById('link');
    link.setAttribute('href', url);
    link.click();
  } else {
    // Le window.setTimeout permet de by-passer le bloquage de la popup par Firefox en executant window.open sur le thread principal
    // Source: https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari/70463940#70463940
    window.setTimeout(() => {
      window.open(url, '_blank').focus();
    });
  }
}

export function redirectWithLanguage(url, language = 'en') {
  // zendesk
  if (url.includes('eficiahelp.zendesk.com/hc/fr/')) {
    if (language === 'en') {
      return url.replace('/fr/', `/en-001/`);
    }
    return url.replace('/fr/', `/${language}/`);
  }

  // https://eficia.com
  if (url.includes('https://eficia.com/')) {
    if (language === 'fr') {
      return url;
    }

    return url.replace('.com/', `.com/${language}/`);
  }

  return url;
}
