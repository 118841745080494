import { useState, useEffect } from 'react';

const DEBOUNCE_DELAY_MS = 250;

// Source: https://www.bacancytechnology.com/qanda/react/use-debounce-with-usequery-in-react-query
function useDebounce(value, delay = DEBOUNCE_DELAY_MS) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export default useDebounce;
