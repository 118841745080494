import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import QuestionColored from 'assets/eficia/icons/question-colored.svg';
import { Icon } from 'eficia/components/atoms/Icon';
import { TranslationContext } from 'eficia/contexts/TranslationProvider';
import { HELP_CENTER_URL } from 'eficia/services/help';
import { handleExternalRedirection, redirectWithLanguage } from 'eficia/utilities/redirections';

export function HelpButtonMenuNonEC({ openFormContactModal }) {
  const { t } = useTranslation();
  const { currentLang } = useContext(TranslationContext);

  const handleRedirectionToHelpCenter = (e) => {
    e.preventDefault();
    handleExternalRedirection(redirectWithLanguage(HELP_CENTER_URL, currentLang?.iso));
  };

  const handleOpenFormContactModal = (e) => {
    e.preventDefault();
    openFormContactModal();
  };

  return (
    <div
      data-testid="help-button-menu"
      className="d-flex flex-column"
      style={{ gap: 18, padding: '4px 16px 8px 16px' }}
    >
      <Typography variant="h5" style={{ fontSize: 16, fontWeight: 600 }}>
        {t('help_button.menu.title')}
      </Typography>

      <a
        href="#"
        onClick={handleRedirectionToHelpCenter}
        className="d-flex flex-row align-items-center"
        style={{ gap: 8 }}
        data-testid="help-button-help-center"
      >
        <span style={{ borderRadius: 4, fontSize: 8, overflow: 'hidden' }}>
          <Icon name="eficia" size={16} />
        </span>
        <Typography variant="h5" style={{ fontSize: 14 }}>
          {t('help_button.menu.help_center')}
        </Typography>
      </a>

      <a
        href="#"
        onClick={handleOpenFormContactModal}
        className="d-flex flex-row align-items-center"
        style={{ gap: 8 }}
        data-testid="help-button-contact-us"
      >
        <img src={QuestionColored} width="16" height="16" alt={t('help_button.menu.contact_us')} />
        <Typography variant="h5" style={{ fontSize: 14 }}>
          {t('help_button.menu.contact_us')}
        </Typography>
      </a>
    </div>
  );
}

HelpButtonMenuNonEC.propTypes = {
  openFormContactModal: PropTypes.func.isRequired
};
