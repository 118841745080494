import React from 'react';

import EquipmentsIcon from 'assets/eficia/icons/equipments.svg';
import GtbIcon from 'assets/eficia/icons/gtb.svg';
import { Icon } from 'eficia/components/atoms/Icon';
import {
  AIR_CURVE_GROUPS_HOME_PATH,
  AIR_CURVE_SITE_HOME_PATH,
  ALERTS_HOME_PATH,
  BAS_ACCESS_SITE_HOME_PATH,
  DASHBOARD_GROUP_HOME_PATH,
  DASHBOARD_SITE_HOME_PATH,
  ELECTRICITY_ACCOUNTING_GROUPS_HOME_PATH,
  ELECTRICITY_ACCOUNTING_SITE_HOME_PATH,
  ELECTRICITY_CURVE_GROUPS_HOME_PATH,
  ELECTRICITY_CURVE_SITE_HOME_PATH,
  ENTITY_HOME_PATH,
  GAS_ACCOUNTING_GROUPS_HOME_PATH,
  GAS_ACCOUNTING_SITE_HOME_PATH,
  GAS_CURVE_GROUPS_HOME_PATH,
  GAS_CURVE_SITE_HOME_PATH,
  HVAC_CURVE_GROUPS_HOME_PATH,
  HVAC_CURVE_SITE_HOME_PATH,
  MISCELLANEOUS_GROUPS_HOME_PATH,
  MISCELLANEOUS_SITE_HOME_PATH,
  NEWS_DASHBOARD_PATH,
  OTHER_EQUIPMENTS_GROUPS_HOME_PATH,
  OTHER_EQUIPMENTS_SITE_HOME_PATH,
  WATER_ACCOUNTING_GROUPS_HOME_PATH,
  WATER_ACCOUNTING_SITE_HOME_PATH,
  WATER_CURVE_GROUPS_HOME_PATH,
  WATER_CURVE_SITE_HOME_PATH
} from 'eficia/constants/paths';

export const NAV_CONFIG = [
  {
    label: '',
    content: [
      {
        label: 'sidebar.menu.understand.filter.site',
        icon: <i className="fa-regular fa-building" />,
        slug: 'sites',
        to: DASHBOARD_SITE_HOME_PATH,
        content: [
          {
            filterTab: 0,
            label: 'sidebar.menu.dashboard_site.label',
            icon: <Icon name="dashboard" />,
            slug: 'dashboard_site',
            to: DASHBOARD_SITE_HOME_PATH
          },
          {
            label: 'sidebar.menu.site.bas_access.label',
            icon: <img src={GtbIcon} alt="" />,
            slug: 'bas_access',
            to: BAS_ACCESS_SITE_HOME_PATH,
            hasAccess: ({ allowedAccesses }) => allowedAccesses?.includes('sites.bas_access')
          },
          {
            label: 'sidebar.menu.site.consumption.label',
            icon: <i className="fa-solid fa-chart-simple" />,
            slug: 'consumption_site',
            content: [
              {
                label: 'sidebar.menu.site.consumption.electricity.label',
                slug: 'electricity_curve_site',
                to: ELECTRICITY_CURVE_SITE_HOME_PATH
              },
              {
                label: 'sidebar.menu.site.consumption.gas.label',
                slug: 'gas_curve_site',
                to: GAS_CURVE_SITE_HOME_PATH
              },
              {
                label: 'sidebar.menu.site.consumption.water.label',
                slug: 'water_curve_site',
                to: WATER_CURVE_SITE_HOME_PATH,
                hasAccess: ({ allowedAccesses }) =>
                  allowedAccesses?.includes('sites.consumption.water')
              }
            ]
          },
          {
            label: 'sidebar.menu.site.air.label',
            icon: <i className="fa-solid fa-temperature-half" />,
            slug: 'air_curve_site',
            to: AIR_CURVE_SITE_HOME_PATH,
            hasAccess: ({ allowedAccesses }) => allowedAccesses?.includes('sites.air')
          },
          {
            label: 'sidebar.menu.site.equipments.label',
            icon: <img src={EquipmentsIcon} alt="" />,
            slug: 'equipments_site',
            content: [
              {
                label: 'sidebar.menu.site.equipments.hvac.label',
                slug: 'hvac_curve_site',
                to: HVAC_CURVE_SITE_HOME_PATH,
                hasAccess: ({ allowedAccesses }) =>
                  allowedAccesses?.includes('sites.equipments.hvac')
              },
              {
                label: 'sidebar.menu.site.equipments.other.label',
                slug: 'other_equipments_site',
                to: OTHER_EQUIPMENTS_SITE_HOME_PATH,
                hasAccess: ({ allowedAccesses }) =>
                  allowedAccesses?.includes('sites.equipments.other')
              }
            ]
          },
          {
            label: 'sidebar.menu.site.accounting.label',
            icon: <i className="fa-solid fa-file-lines" />,
            slug: 'accounting_site',
            content: [
              {
                label: 'sidebar.menu.site.accounting.electricity.label',
                slug: 'electricity_accounting_site',
                to: ELECTRICITY_ACCOUNTING_SITE_HOME_PATH,
                hasAccess: ({ allowedAccesses }) =>
                  allowedAccesses?.includes('sites.accounting.electricity')
              },
              {
                label: 'sidebar.menu.site.accounting.gas.label',
                slug: 'gas_accounting_site',
                to: GAS_ACCOUNTING_SITE_HOME_PATH,
                hasAccess: ({ allowedAccesses }) =>
                  allowedAccesses?.includes('sites.accounting.gas')
              },
              {
                label: 'sidebar.menu.site.accounting.water.label',
                slug: 'water_accounting_site',
                to: WATER_ACCOUNTING_SITE_HOME_PATH,
                hasAccess: ({ allowedAccesses }) =>
                  allowedAccesses?.includes('sites.accounting.water')
              }
            ]
          },
          {
            label: 'sidebar.menu.site.miscellaneous.label',
            icon: <i className="fa-solid fa-ellipsis" />,
            slug: 'miscellaneous_site',
            to: MISCELLANEOUS_SITE_HOME_PATH,
            hasAccess: ({ allowedAccesses }) => allowedAccesses?.includes('sites.miscellaneous')
          }
        ]
      },
      {
        label: 'sidebar.menu.understand.filter.group',
        icon: <i style={{ fontSize: 24 }} className="fa-kit fa-buildings-solid" />,
        slug: 'groups',
        to: DASHBOARD_GROUP_HOME_PATH,
        content: [
          {
            filterTab: 1,
            label: 'sidebar.menu.dashboard_group.label',
            icon: <Icon name="dashboard" />,
            slug: 'dashboard_group',
            to: DASHBOARD_GROUP_HOME_PATH
          },
          {
            label: 'sidebar.menu.group.consumption.label',
            icon: <i className="fa-solid fa-chart-simple" />,
            slug: 'consumption_group',
            content: [
              {
                label: 'sidebar.menu.group.consumption.electricity.label',
                slug: 'electricity_curve_group',
                to: ELECTRICITY_CURVE_GROUPS_HOME_PATH
              },
              {
                label: 'sidebar.menu.group.consumption.gas.label',
                slug: 'gas_curve_group',
                to: GAS_CURVE_GROUPS_HOME_PATH
              },
              {
                label: 'sidebar.menu.group.consumption.water.label',
                slug: 'water_curve_group',
                to: WATER_CURVE_GROUPS_HOME_PATH,
                hasAccess: ({ allowedAccesses }) =>
                  allowedAccesses?.includes('groups.consumption.water')
              }
            ]
          },
          {
            label: 'sidebar.menu.group.air.label',
            icon: <i className="fa-solid fa-temperature-half" />,
            slug: 'air_curve_group',
            to: AIR_CURVE_GROUPS_HOME_PATH,
            hasAccess: ({ allowedAccesses }) => allowedAccesses?.includes('groups.air')
          },
          {
            label: 'sidebar.menu.group.equipments.label',
            icon: <img src={EquipmentsIcon} alt="" />,
            slug: 'equipments_group',
            content: [
              {
                label: 'sidebar.menu.group.equipments.hvac.label',
                slug: 'hvac_curve_group',
                to: HVAC_CURVE_GROUPS_HOME_PATH,
                hasAccess: ({ allowedAccesses }) =>
                  allowedAccesses?.includes('groups.equipments.hvac')
              },
              {
                label: 'sidebar.menu.group.equipments.other.label',
                slug: 'other_equipments_group',
                to: OTHER_EQUIPMENTS_GROUPS_HOME_PATH,
                hasAccess: ({ allowedAccesses }) =>
                  allowedAccesses?.includes('groups.equipments.other')
              }
            ]
          },

          {
            label: 'sidebar.menu.group.accounting.label',
            icon: <i className="fa-solid fa-file-lines" />,
            slug: 'accounting_group',
            content: [
              {
                label: 'sidebar.menu.group.accounting.electricity.label',
                slug: 'electricity_accounting_group',
                to: ELECTRICITY_ACCOUNTING_GROUPS_HOME_PATH,
                hasAccess: ({ allowedAccesses }) =>
                  allowedAccesses?.includes('groups.accounting.electricity')
              },
              {
                label: 'sidebar.menu.group.accounting.gas.label',
                slug: 'gas_accounting_group',
                to: GAS_ACCOUNTING_GROUPS_HOME_PATH,
                hasAccess: ({ allowedAccesses }) =>
                  allowedAccesses?.includes('groups.accounting.gas')
              },
              {
                label: 'sidebar.menu.group.accounting.water.label',
                slug: 'water_accounting_group',
                to: WATER_ACCOUNTING_GROUPS_HOME_PATH,
                hasAccess: ({ allowedAccesses }) =>
                  allowedAccesses?.includes('groups.accounting.water')
              }
            ]
          },
          {
            label: 'sidebar.menu.group.miscellaneous.label',
            icon: <i className="fa-solid fa-ellipsis" />,
            slug: 'miscellaneous_group',
            to: MISCELLANEOUS_GROUPS_HOME_PATH,
            hasAccess: ({ allowedAccesses }) => allowedAccesses?.includes('groups.miscellaneous')
          }
        ]
      },
      {
        label: 'sidebar.menu.organisation.label',
        icon: <i className="fa-solid fa-gear" />,
        slug: 'organization',
        content: [
          {
            label: 'sidebar.menu.sites.label',
            to: `${ENTITY_HOME_PATH}/sites`,
            slug: 'entity',
            icon: <i className="fa-solid fa-building" />,
            hasAccess: ({ groupNotFound }) => !groupNotFound
          },
          {
            label: 'sidebar.menu.groups.label',
            to: `${ENTITY_HOME_PATH}/groups`,
            slug: 'entity',
            icon: <Icon name="group" />,
            hasAccess: ({ userSidebarPermissions }) =>
              userSidebarPermissions?.includes('display-group-list')
          },
          {
            label: 'sidebar.menu.users.label',
            to: `${ENTITY_HOME_PATH}/users`,
            slug: 'entity',
            icon: <Icon name="users" />,
            hasAccess: ({ userSidebarPermissions }) =>
              userSidebarPermissions?.includes('display-user-list')
          },
          {
            label: 'sidebar.menu.informations.label',
            to: `${ENTITY_HOME_PATH}/organization`,
            slug: 'entity',
            icon: <Icon name="organization" />
          }
        ]
      },
      {
        label: 'sidebar.menu.alerts.label',
        icon: <Icon name="alert" />,
        slug: 'alerts',
        to: ALERTS_HOME_PATH
      },
      {
        label: 'sidebar.menu.news.label',
        icon: <Icon name="news" />,
        slug: 'news',
        to: NEWS_DASHBOARD_PATH,
        hasAccess: ({ userData }) => userData?.countries?.some((country) => country.id === 'FRA')
      }
    ]
  }
];
