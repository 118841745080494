import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import { LayoutContext } from 'eficia/contexts/LayoutProvider';
import { TranslationContext } from 'eficia/contexts/TranslationProvider';
import { handleExternalRedirection, redirectWithLanguage } from 'eficia/utilities/redirections';

import { AppFooter } from './Footer.style';

export default function Footer() {
  const { t } = useTranslation();
  const { currentLang } = useContext(TranslationContext);

  const withoutVisibleSidebar = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  const { rightSidebarContent } = useContext(LayoutContext);

  function handleRedirection() {
    handleExternalRedirection(redirectWithLanguage('https://eficia.com/', currentLang?.iso));
  }

  if (rightSidebarContent) {
    // 2024-11-19: Afin de livrer le chatbot AI dans les temps, nous avons décidé de
    // ne pas rendre clickable le contenu central de la page une fois la Right Sidebar ouverte.
    // TODO: Rendre la partie centrale des pages cliquable une fois la Right Sidebar ouverte.
    return;
  }

  return (
    <AppFooter $withoutVisibleSidebar={withoutVisibleSidebar} data-testid="footer">
      <Link onClick={handleRedirection}>
        <span>©{new Date().getFullYear()} Eficia</span>
      </Link>
      <span>|</span>
      <a
        target="_blank"
        href={redirectWithLanguage(
          'https://eficiahelp.zendesk.com/hc/fr/articles/6856842797341/',
          currentLang?.iso
        )}
      >
        <span>{t('footer.terms_of_use')}</span>
      </a>

      <span>|</span>
      <a
        target="_blank"
        href={redirectWithLanguage('https://eficiahelp.zendesk.com/hc/fr/', currentLang?.iso)}
      >
        <span>{t('footer.help_center')}</span>
      </a>
      <span>|</span>
      <a
        target="_blank"
        href={redirectWithLanguage(
          'https://eficiahelp.zendesk.com/hc/fr/articles/6539998577949-FAQ',
          currentLang?.iso
        )}
      >
        <span>{t('footer.frequently_asked_question')}</span>
      </a>
    </AppFooter>
  );
}
